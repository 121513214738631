import React from "react"
import { graphql, PageProps } from "gatsby"
import { ParallaxProvider } from "react-scroll-parallax"
import HomeLoloPage from "@components/HomeLolo"
import LayoutContainer from "@containers/Layout"
import SEO from "@components/SEO"
import { LoloHomeDataType } from "@typings/requests/home/lolo-home"
import "./index.scss"

const LoloHomePage: React.FC<PageProps<LoloHomeDataType>> = ({
  data,
  location,
}) => {
  const {
    allStrapiIndexPage: {
      nodes: [indexPageData],
    },
    allStrapiPageFooter: {
      nodes: [footerData],
    },
    allStrapiPosts: { nodes: blogPostsData },
  } = data

  return (
    <>
      <SEO
        title={indexPageData.title}
        description={indexPageData.description}
        pathname={location.pathname}
        lang={indexPageData.locale}
        image={indexPageData.metaImage?.localFile.url}
      />
      <ParallaxProvider>
        <LayoutContainer className="index" location={location} lang="es">
          <HomeLoloPage
            data={indexPageData}
            footerData={footerData}
            blogPostsData={blogPostsData as []}
          />
        </LayoutContainer>
      </ParallaxProvider>
    </>
  )
}

export const query = graphql`
  {
    allStrapiIndexPage(filter: { locale: { eq: "es" } }) {
      nodes {
        description
        id
        title
        locale
        newsTitle
        indexHero {
          appleLink
          description {
            data {
              description
            }
          }
          downloadLabel
          googleLink
          title
          firstImage {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 378
                  quality: 90
                  placeholder: BLURRED
                  layout: CONSTRAINED
                )
              }
            }
          }
          secondImage {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 378
                  quality: 90
                  placeholder: BLURRED
                  layout: CONSTRAINED
                )
              }
            }
          }
        }
        indexInfo {
          buttonDescription
          buttonLabel
          description {
            data {
              description
            }
          }
          title
        }
        metaImage {
          localFile {
            publicURL
          }
        }
        riders {
          title
          description
          info {
            data {
              info
            }
          }
          appStoreLink
          storesTitle
          googlePlayLink
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 420
                  quality: 95
                  placeholder: BLURRED
                  layout: CONSTRAINED
                )
              }
            }
          }
        }
        partners {
          id
          title
          description
          info {
            data {
              info
            }
          }
          buttonLink: button {
            label
            url
          }
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 420
                  quality: 95
                  placeholder: BLURRED
                  layout: CONSTRAINED
                )
              }
            }
          }
        }
        driver {
          buttonLabel
          buttonTitle
          description
          info {
            data {
              info
            }
          }
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 420
                  quality: 95
                  placeholder: BLURRED
                  layout: CONSTRAINED
                )
              }
            }
          }
          title
        }
        indexModal {
          button {
            label
            url
            id
          }
          title {
            data {
              title
            }
          }
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 505
                  quality: 90
                  placeholder: BLURRED
                  layout: CONSTRAINED
                )
              }
            }
          }
        }
      }
    }
    allStrapiPosts(
      filter: { locale: { eq: "es" }, isFeatured: { eq: true } }
      limit: 3
      sort: { order: DESC, fields: publishedAt }
    ) {
      nodes {
        id
        postDescription
        slug
        locale
        tags {
          id
          name
          title
        }
        image {
          localFile {
            childImageSharp {
              gatsbyImageData(
                quality: 90
                layout: CONSTRAINED
                width: 375
                height: 245
              )
            }
          }
        }
        title
        publishedAt(formatString: "YYYY-MM-DD", locale: "es")
      }
    }
    allStrapiPageFooter(filter: { locale: { eq: "es" } }) {
      nodes {
        title
        socialLink {
          name
          title
          url
        }
      }
    }
  }
`
export default LoloHomePage
